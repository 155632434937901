@import url("fonts.css");
@import url("animate.css");
@import url("normalize.css/normalize.css");

*,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
}

html {
  height: 100%;
}

body {
  height: 100%;
  line-height: 1.5;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--size-text-s);
  font-family: var(--font-primary);
  color: var(--color-typo-primary);
  background: var(--color-bg-default);
}

a {
  text-decoration: none;
  color: var(--color-typo-brand);
}

ul {
  padding: 0;
  list-style: none;
}

.root {
  height: 100%;
}

/* Firefox */
.scrollbar * {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.24) rgba(255, 255, 255, 0.24);
}

/* Works on Chrome, Edge */
.scrollbar *::-webkit-scrollbar {
  width: 8px;
}

.scrollbar *::-webkit-scrollbar-track,
.scrollbar *::-webkit-scrollbar-corner {
  background: transparent;
}

.scrollbar *::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.24);
}

.scrollbar *::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.4);
}
