@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBMPlexSans-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBMPlexSans-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../fonts/IBMPlexSans-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
