.theme_dark {
  --color-bg-default: #000;
  --color-bg-secondary: #181a1d;
  --color-bg-dark-primary: #24272b;
  --color-bg-dark-primary-rare: #2f353c;
  --color-bg-tone: #212326;
  --color-bg-tool: #415451;
  --color-bg-tool-dark: #27312e;
  --color-bg-tool-light: #33413d;
  --color-bg-dark-second: #394844;
  --color-typo-primary: #fff;
  --color-typo-secondary: #86939e;
  --color-typo-brand: #6aead4;
  --color-typo-ghost: rgba(255, 255, 255, 0.5);
  --color-typo-hover: rgba(255, 255, 255, 0.65);
  --color-typo-disabled: rgba(255, 255, 255, 0.2);
  --color-typo-inactive: #212326;
  --color-typo-error: #ff4330;
  --color-typo-warning: #f5a623;
  --color-typo-success: #75ff7e;
  --color-typo-action-needed: #4abcfd;
  --font-primary: "IBM Plex Sans", sans-serif;

  /* Color scheme */
  --color-scheme: dark;

  /* Link */
  --color-secondary-link-hover: #a2b0bb;

  /* Typography */
  --size-text-2xs: 10px;
  --size-text-xs: 12px;
  --size-text-s: 14px;
  --size-text-m: 16px;
  --size-text-l: 20px;
  --size-text-xl: 24px;
  --size-text-2xl: 28px;
  --size-text-3xl: 32px;
  --size-text-4xl: 42px;

  /* Button */
  --button-color: #222426;
  --button-background: #6aead4;
  --button-background-hover: #74ffe7;
  --button-color-secondary: #fff;
  --button-background-ghost: rgba(255, 255, 255, 0.05);
  --button-background-secondary: #3f464f;
  --button-background-secondary-hover: #48505a;
  --button-background-positive: #75ff7e;
  --button-background-positive-hover: #85ff8d;
  --button-background-negative: #e74c3c;
  --button-background-negative-hover: #f2594a;
  --button-background-decline: #f5a623;
  --button-background-decline-hover: #f6b344;

  /* Label */
  --form-label-color: rgba(255, 255, 255, 0.5);

  /* Input */
  --input-color: #fff;
  --input-height: 56px;
  --input-font-size: 14px;
  --input-border-color: rgba(255, 255, 255, 0.1);

  /* Control */
  --control-radius: 4px;
  --control-background: #3f464f;
  --control-height-size-xs: 24px;
  --control-height-size-s: 32px;
  --control-height-size-m: 40px;
  --control-height-size-l: 48px;
  --control-height-size-xl: 56px;
  --control-height-size-xxl: 66px;

  /* Visual data separator */
  --vertical-border-separator: rgba(255, 255, 255, 0.05);
  --vertical-border-separator-secondary: rgba(255, 255, 255, 0.15);

  /* Alert */
  --alert-background: #313e3c;
  --alert-background-secondary: #3a4148;
  --alert-gradient-success: 90deg, rgba(117, 255, 126, 0.15) 0%, rgba(117, 255, 126, 0) 25%;
  --alert-gradient-error: 90deg, rgba(231, 76, 60, 0.3) 0%, rgba(231, 76, 60, 0) 38%;
  --alert-gradient-warning: 90deg, rgba(245, 166, 35, 0.2) 0%, rgba(245, 166, 35, 0) 46%;
  --alert-gradient-info: 90deg, rgba(124, 197, 220, 0.3) 0%, rgba(124, 197, 220, 0) 25%;

  /* Banner */
  --banner-alert-background: #9a2500;
  --banner-warning-background: #b7730c;
  --banner-informer-background: #077;
}
